import React from "react";
import "./style.scss";
import one from "./img/counter.gif";
import two from "./img/vga.gif";
import three from "./img/fsm.png";
import four from "./img/filler.png";

const slides = [
  {
    title: "2 Digit Counter",
    image:
      one,
    description: "A simple two digit counter implemented in Verilog.\nClick the project title to learn more.",
	link: "https://github.com/zatebe/Digital-IC-Projects/tree/main/counter"
  },
  {
    title: "VGA",
    image:
      two,
    description: "VGA signal generation of a square that bounces off the bounds.\nClick the project title to learn more.",
	link: "https://github.com/zatebe/Digital-IC-Projects/tree/main/vga"
  },
  {
    title: "Moore FSM",
    image: three,
    description: "A simple Moore FSM implemented in Verilog.\nClick the project title to learn more.",
	link: "https://github.com/zatebe/Digital-IC-Projects/tree/main/moore"

  },
  {
    title: "Project 4",
    image:
      four,
    description: "Coming soon...",
	link: ""
  }
];

function getOffset(index, offset) {
  if (offset === -1) {
    index = index === 0 ? slides.length - 1 : index - 1;
  } else if (offset === 1) {
    index = (index + 1) % slides.length;
  }

  return index;
}

function reducer(state, action) {
  var i = action === "FORWARD" ? 1 : -1;
  return getOffset(state, i);
}

function Slide(slide, offset, index) {
  const ref = React.createRef();

  return (
    <div
      ref={ref}
      className="slide"
      focus={offset}
      style={{
        "--offset": offset
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.image}')`
        }}
      />
      <div className="slideContentInner">
        <h2 className="slideTitle"><a href={slides[index].link}>{slides[index].title}</a></h2>
        <p className="slideDesc">{slide.description}</p>
      </div>
    </div>
  );
}

export default function App() {
  const [state, dispatch] = React.useReducer(reducer, 0);

  function backward() {
    dispatch("BACKWARD");
  }

  function forward() {
    dispatch("FORWARD");
  }

  return (
    <div className="slides">
      <button onClick={backward} />
      {Slide(slides[getOffset(state, -1)], -1, state)}
      {Slide(slides[state], 0, state)}
      {Slide(slides[getOffset(state, 1)], 1, state)}
      <button onClick={forward} />
    </div>
  );
}
